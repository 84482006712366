import { CluesInputOriginal } from "react-crossword-v2/dist/types";

const crosswords: CluesInputOriginal[] = [
  // 1
  {
    across: {
      '1': {
        clue: '',
        answer: 'ABCS',
        row: 0,
        col: 0,
      },
      '5': {
        clue: '',
        answer: 'DRAW',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'SONIA',
        row: 2,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'KING',
        row: 3,
        col: 1,
      },
      '9': {
        clue: '',
        answer: 'EDGE',
        row: 4,
        col: 1,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'ADS',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'BROKE',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'CANID',
        row: 0,
        col: 2,
      },
      '4': {
        clue: '',
        answer: 'SWING',
        row: 0,
        col: 3,
      },
      '7': {
        clue: '',
        answer: 'AGE',
        row: 2,
        col: 4,
      }
    },
  },
  // 2
  {
    across: {
      '1': {
        clue: '',
        answer: 'JARS',
        row: 0,
        col: 1,
      },
      '5': {
        clue: '',
        answer: 'MELON',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'ALIBI',
        row: 2,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'SLEEP',
        row: 3,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'TONS',
        row: 4,
        col: 0,
      },
    },
    down: {
      '1': {
        clue: '',
        answer: 'JELLO',
        row: 0,
        col: 1,
      },
      '2': {
        clue: '',
        answer: 'ALIEN',
        row: 0,
        col: 2,
      },
      '3': {
        clue: '',
        answer: 'ROBES',
        row: 0,
        col: 3,
      },
      '4': {
        clue: '',
        answer: 'SNIP',
        row: 0,
        col: 4,
      },
      '5': {
        clue: '',
        answer: 'MAST',
        row: 1,
        col: 0,
      },
    }
  },
  // 3
  {
    across: {
      '1': {
        clue: '',
        answer: 'SUN',
        row: 0,
        col: 0,
      },
      '4': {
        clue: '',
        answer: 'ONION',
        row: 1,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'ASTRO',
        row: 2,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'RERAN',
        row: 3,
        col: 0,
      },
      '9': {
        clue: '',
        answer: 'STOLE',
        row: 4,
        col: 0,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'SOARS',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'UNSET',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'NITRO',
        row: 0,
        col: 2,
      },
      '5': {
        clue: '',
        answer: 'ORAL',
        row: 1,
        col: 3,
      },
      '6': {
        clue: '',
        answer: 'NONE',
        row: 1,
        col: 4,
      }
    }
  },
  // 4
  {
    across: {
      '1': {
        clue: '',
        answer: 'MUCK',
        row: 0,
        col: 1,
      },
      '5': {
        clue: '',
        answer: 'SUSHI',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'ASHEN',
        row: 2,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'TEES',
        row: 3,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'DRS',
        row: 4,
        col: 1,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'MUSED',
        row: 0,
        col: 1,
      },
      '2': {
        clue: '',
        answer: 'USHER',
        row: 0,
        col: 2,
      },
      '3': {
        clue: '',
        answer: 'CHESS',
        row: 0,
        col: 3,
      },
      '4': {
        clue: '',
        answer: 'KIN',
        row: 0,
        col: 4,
      },
      '5': {
        clue: '',
        answer: 'SAT',
        row: 1,
        col: 0,
      }
    }
  },
  // 5
  {
    across: {
      '1': {
        clue: '',
        answer: 'HURT',
        row: 0,
        col: 0,
      },
      '5': {
        clue: '',
        answer: 'UNHIP',
        row: 1,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'HAITI',
        row: 2,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'GNAT',
        row: 3,
        col: 1,
      },
      '9': {
        clue: '',
        answer: 'IONS',
        row: 4,
        col: 1,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'HUH',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'UNAGI',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'RHINO',
        row: 0,
        col: 2,
      },
      '4': {
        clue: '',
        answer: 'TITAN',
        row: 0,
        col: 3,
      },
      '6': {
        clue: '',
        answer: 'PITS',
        row: 1,
        col: 4,
      }
    }
  },
  // 6
  {
    across: {
      '1': {
        clue: '',
        answer: 'RAD',
        row: 0,
        col: 1,
      },
      '4': {
        clue: '',
        answer: 'POLED',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'EBONY',
        row: 2,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'RINSE',
        row: 3,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'KNEE',
        row: 4,
        col: 0,
      },
    },
    down: {
      '1': {
        clue: '',
        answer: 'ROBIN',
        row: 0,
        col: 1,
      },
      '2': {
        clue: '',
        answer: 'ALONE',
        row: 0,
        col: 2,
      },
      '3': {
        clue: '',
        answer: 'DENSE',
        row: 0,
        col: 3,
      },
      '4': {
        clue: '',
        answer: 'PERK',
        row: 1,
        col: 0,
      },
      '5': {
        clue: '',
        answer: 'DYE',
        row: 1,
        col: 4,
      },
    }
  },
  // 7
  {
    across: {
      '1': {
        clue: '',
        answer: 'I',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'BRAND',
        row: 1,
        col: 0,
      },
      '5': {
        clue: '',
        answer: 'OX',
        row: 2,
        col: 1,
      },
      '6': {
        clue: '',
        answer: 'SNEAK',
        row: 3,
        col: 0,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'IRONY',
        row: 0,
        col: 1,
      },
      '2': {
        clue: '',
        answer: 'ON',
        row: 0,
        col: 3,
      },
      '4': {
        clue: '',
        answer: 'AXE',
        row: 1,
        col: 2
      },
      '7': {
        clue: '',
        answer: 'AS',
        row: 3,
        col: 3,
      }
    }
  },
  // 8
  {
    across: {
      '1': {
        clue: '',
        answer: 'IT',
        row: 0,
        col: 0,
      },
      '3': {
        clue: '',
        answer: 'HI',
        row: 0,
        col: 3,
      },
      '5': {
        clue: '',
        answer: 'FACET',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'OOZED',
        row: 3,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'NO',
        row: 4,
        col: 0,
      },
      '9': {
        clue: '',
        answer: 'DO',
        row: 4,
        col: 3,
      }
    },
    down: {
      '1': {
        clue: '',
        answer: 'IF',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'TABOO',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'HEXED',
        row: 0,
        col: 3,
      },
      '4': {
        clue: '',
        answer: 'IT',
        row: 0,
        col: 4,
      },
      '6': {
        clue: '',
        answer: 'ON',
        row: 3,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'DO',
        row: 3,
        col: 4,
      }
    },
  },
  // 9
  {
    across: {
      '1': {
        clue: '',
        answer: 'HARSH',
        row: 0,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'ABATE',
        row: 1,
        col: 0,
      },
      '7': {
        clue: '',
        answer: 'ROTOR',
        row: 2,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'TV',
        row: 3,
        col: 0,
      },
      '9': {
        clue: '',
        answer: 'NO',
        row: 3,
        col: 3,
      },
      '10': {
        clue: '',
        answer: 'SEVEN',
        row: 4,
        col: 0,
      },
    },
    down: {
      '1': {
        clue: '',
        answer: 'HARTS',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'ABOVE',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'RAT',
        row: 0,
        col: 2,
      },
      '4': {
        clue: '',
        answer: 'STONE',
        row: 0,
        col: 3,
      },
      '5': {
        clue: '',
        answer: 'HERON',
        row: 0,
        col: 4,
      },
    }
  },
  // 10
  {
    across: {
      '1': {
        clue: '',
        answer: 'ORCA',
        row: 0,
        col: 0,
      },
      '5': {
        clue: '',
        answer: 'DEAL',
        row: 1,
        col: 0,
      },
      '6': {
        clue: '',
        answer: 'DENIM',
        row: 2,
        col: 0,
      },
      '8': {
        clue: '',
        answer: 'LAVA',
        row: 3,
        col: 1,
      },
      '9': {
        clue: '',
        answer: 'SLEW',
        row: 4,
        col: 1,
      },
    },
    down: {
      '1': {
        clue: '',
        answer: 'ODD',
        row: 0,
        col: 0,
      },
      '2': {
        clue: '',
        answer: 'REELS',
        row: 0,
        col: 1,
      },
      '3': {
        clue: '',
        answer: 'CANAL',
        row: 0,
        col: 2,
      },
      '4': {
        clue: '',
        answer: 'ALIVE',
        row: 0,
        col: 3,
      },
      '7': {
        clue: '',
        answer: 'MAW',
        row: 2,
        col: 4,
      },
    }
  },
];

export default crosswords;
